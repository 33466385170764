<template
  >{{ user }}
  <form @submit.prevent="onSubmit">
    <div class="modal-wrap">
      <div class="grid">
        <div class="col">
          <Divider align="left" class="divider-feature">
            <div class="p-d-inline-flex p-ai-center">User Type</div>
          </Divider>
        </div>
      </div>
      <div
        class="hasValidation"
        :class="{
          error: v$.user.isUser.$error,
          error: v$.user.isSignatory.$error,
          error: v$.user.isNotary.$error
        }"
      >
        <div class="grid mt-2">
          <div class="col">
            <Checkbox id="usertype-user" v-model="user.isUser" :binary="true" />
            <label class="pl-2" for="usertype-user">System User</label>
          </div>
          <div class="col">
            <Checkbox
              id="usertype-sig"
              v-model="user.isSignatory"
              :binary="true"
              :disabled="user.manageApplications"
            />
            <label class="pl-2" for="usertype-sig">Signatory</label>
          </div>
          <div class="col">
            <Checkbox id="usertype-notary" v-model="user.isNotary" :binary="true" />
            <label class="pl-2" for="usertype-notary">Notary</label>
          </div>
        </div>
        <small class="helpText">Select at least one role for this person</small>
      </div>

      <div class="grid mt-3">
        <div class="col">
          <Divider align="left" class="divider-feature">
            <div class="p-d-inline-flex p-ai-center">User Information</div>
          </Divider>
        </div>
      </div>

      <div class="grid mt-3">
        <div class="col">
          <div
            class="p-float-label p-fluid hasValidation"
            :class="{ error: v$.user.firstname.$error }"
          >
            <InputText v-model="user.firstname" id="user-firstname" />
            <label for="user-firstname">First Name</label>
          </div>
        </div>
        <div class="col">
          <div
            class="p-float-label p-fluid hasValidation"
            :class="{ error: v$.user.lastname.$error }"
          >
            <InputText v-model="user.lastname" id="user-lastname" />
            <label for="user-lastname">Last Name</label>
          </div>
        </div>
      </div>

      <div class="grid mt-3">
        <div class="col">
          <transition name="fade" mode="out-in">
          <section v-if="user.isSignatory">
            <div
              class="p-float-label p-fluid hasValidation"
              :class="{ error: v$.user.title.$error }"
            >
              <InputText v-model="user.title" id="user-title" />
              <label for="user-title">Title</label>
            </div>
          </section>
          </transition>
        </div>
        <div class="col">
          <transition name="fade" mode="out-in">
          <section v-if="user.isUser">
            <div
              class="p-float-label p-fluid hasValidation"
              :class="{ error: v$.user.email.$error }"
            >
              <InputText v-model="user.email" id="user-email" />
              <label for="user-email">Email</label>
            </div>
          </section>
          </transition>
        </div>
      </div>

      <!----Gender IF Notary---->
      <transition name="fade" mode="out-in">
        <section v-if="user.isNotary">
          <div
            class="hasValidation"
            :class="{
              error: v$.user.gender.$error
            }"
          >
            <div class="grid mt-3">
              <Divider align="left" class="divider-feature">
                <div class="p-d-inline-flex p-ai-center">Gender Identity</div>
              </Divider>
            </div>
            <div class="grid">
              <div class="col">
                <div class="formgroup-inline ">
                  <div class="field-radiobutton">
                    <RadioButton id="gender-male" name="gender" value="m" v-model="user.gender" />
                    <label for="gender-male">Male</label>
                  </div>
                  <div class="field-radiobutton">
                    <RadioButton id="gender-female" name="gender" value="f" v-model="user.gender" />
                    <label for="gender-female">Female</label>
                  </div>
                </div>
              </div>
            </div>
            <small class="helpText">Gender identity is required for all notaries</small>
          </div>
        </section>
      </transition>

      <!---Signature File IF Signatory--->
      <transition name="fade" mode="out-in">
        <section v-if="user.isSignatory">
          <div class="grid">
            <div class="col">
              <Divider align="left" class="divider-feature">
                <div class="p-d-inline-flex p-ai-center">Signature Image</div>
              </Divider>
              <div v-if="!loading">
                <label>
                  <span class="span-button"><i class="fas fa-upload"></i> Upload File</span>
                  <input
                    type="file"
                    name="document"
                    id="document"
                    @change="upload"
                    accept="application/pdf"
                  />
                </label>
              </div>
            </div>
          </div>
        </section>
      </transition>

      <!---Permissions IF System User--->
      <transition name="fade" mode="out-in">
        <section v-if="user.isUser">
          <div class="grid">
            <div class="col">
              <Divider align="left" class="divider-feature">
                <div class="p-d-inline-flex p-ai-center">Permissions</div>
              </Divider>
            </div>
          </div>

          <div class="grid mt-2">
            <div class="col">
              <Checkbox id="permission-superuser" v-model="user.isSuperUser" :binary="true" />
              <label class="pl-2" for="permission-superuser">Enable "Super User"</label>
            </div>
          </div>
          <div class="grid mt-2">
            <div class="col">
              <Checkbox
                id="permission-applications"
                v-model="user.manageApplications"
                :binary="true"
                :disabled="user.isSuperUser"
              />
              <label class="pl-2" for="permission-applications">Can manage applications</label>
            </div>
          </div>
          <div class="grid mt-2">
            <div class="col">
              <Checkbox
                id="permission-companies"
                v-model="user.manageCompanies"
                :binary="true"
                :disabled="user.isSuperUser"
              />
              <label class="pl-2" for="permission-companies">Can manage companies</label>
            </div>
          </div>
          <div class="grid mt-2">
            <div class="col">
              <Checkbox
                id="permission-reports"
                v-model="user.viewReports"
                :binary="true"
                :disabled="user.isSuperUser"
              />
              <label class="pl-2" for="permission-reports">Can view reports</label>
            </div>
          </div>
          <div class="grid mt-2">
            <div class="col">
              <Checkbox
                id="permission-options"
                v-model="user.manageOtherSettings"
                :binary="true"
                :disabled="user.isSuperUser"
              />
              <label class="pl-2" for="permission-options">Can manage all options</label>
            </div>
          </div>
        </section>
      </transition>
      <hr />
      <div class="grid mt-2">
        <div class="col text-right">
          <Button label="Save" type="submit" />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import Divider from "primevue/divider";
import useVuelidate from "@vuelidate/core";
import validations from "./UserFormValidations.js";

export default {
  name: "Chamber User Form",
  setup() {
    return { v$: useVuelidate() };
  }, //setup()
  validations,
  props: ["currentUser"],

  components: { Divider },
  data() {
    return {
      show: "false",
      loading: true,
      user: {}
    };
  },
  created() {
    this.user = JSON.parse(JSON.stringify(this.currentUser));
  }, //created()
  emits: ["save", "close"],

  methods: {
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$invalid) return false;
      this.$emit("save", this.user);
    }
  }, //methods
  computed: {}, //computed
  watch: {
    currentUser() {
      this.user = JSON.parse(JSON.stringify(this.currentUser));
    },
    "user.isUser": function(value) {
      if (!value) {
        this.user.isSuperUser = false;
        this.user.manageApplications = false;
        this.user.manageCompanies = false;
        this.user.viewReports = false;
        this.user.manageOtherSettings = false;
      }
    },
    "user.isSuperUser": function(value) {
      if (value) {
        this.user.manageApplications = true;
        this.user.manageCompanies = true;
        this.user.viewReports = true;
        this.user.manageOtherSettings = true;
      }
    },
    "user.manageApplications": function(value) {
      if (value) this.user.isSignatory = true;
    }
  } //
};
</script>

<style>
.forAnim {
  max-height: 1000px;
}

.hasValidation .helpText {
  color: red;
  display: none;
}

.hasValidation.error .helpText {
  display: block;
}

.p-float-label.error input {
  border: 1px solid red;
}
</style>
