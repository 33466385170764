<template>
  <h6>Chamber decline Options</h6>
</template>
<script>
export default {

}
</script>

<style>

</style>