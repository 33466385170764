<template>
  <DataTable
    :value="users"
    class="p-datatable-customers"
    dataKey="systemUserId"
    responsiveLayout="scroll"
    :scrollable="true"
    scrollHeight="flex"
    :paginator="true"
    :rows="25"
    v-model:filters="filter"
    :globalFilterFields="['firstname', 'lastname']"
    :loading="loading"
    contextMenu
    v-model:contextMenuSelection="selected"
    @row-contextmenu="onRowContextMenu"

    rowGroupMode="subheader" groupRowsBy="cocId"
  >
    <template #loading>
      Loading records, please wait...
    </template>
    <template #header>
      <div class="fw-header">
        <div>
          <h6 class="mt-2 ml-2">
            <span class="p-menuitem-icon fas fa-stream" aria-hidden="true"></span> Chamber Users

            <i class="fa fa-plus p-2" @click="create"></i>
          </h6>
        </div>

        <div class="w-full">
          <span class="p-input-icon-left w-full">
            <i class="fa fa-search" />
            <InputText
              v-model="filter['global'].value"
              placeholder="Search"
              class="fullWidthInput"
              type="search"
            />
          </span>
        </div>

        <div>
          <button
            class="p-button p-component p-button-icon-only p-button-rounded-light ml-2"
            type="button"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          >
            <span class="fa fa-filter p-button-icon" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </template>

    <template #groupheader="{data}" class="icon-col">
        <h6>{{chamber(data.cocId, 'coc') }}</h6>
    </template>

    <column header="Chamber" class="icon-col">
      <template #body="{data}">
        {{ chamber(data.cocId, 'code') }}
      </template>
    </column>



    <Column header="User Name">
      <template #body="{data}">
        {{ data.firstname + " " + data.lastname }} {{data.isDeleted}}
      </template>
    </Column>

    <column header="Active" class="icon-col">
      <template #body="{data}">
        <i class="fas" :class="{ 'fa-check': data.isActive, 'fa-times': !data.isActive }"></i>
      </template>
    </column>

    <column header="User" class="icon-col">
      <template #body="{data}">
        <i
          class="fas"
          :class="{ 'fa-check': data.isUser, 'fa-check-double': data.isSuperUser }"
        ></i>
      </template>
    </column>

    <column header="Signatory" class="icon-col">
      <template #body="{data}">
        <i class="fas" :class="{ 'fa-check': data.isSignatory }"></i>
      </template>
    </column>

    <column header="Notary" class="icon-col">
      <template #body="{data}">
        <i class="fas" :class="{ 'fa-check': data.isNotary }"></i>
      </template>
    </column>
  </DataTable>

  <Dialog header="User Details" v-model:visible="showUserForm" v-bind:user="selected" :modal="true">
    <user-form :currentUser="selected" @save="save"></user-form>
  </Dialog>
  <ContextMenu :model="contextMenu" ref="cm" />
</template>

<script>
import ContextMenu from "primevue/contextmenu";
import { FilterMatchMode } from "primevue/api";
import Dialog from "primevue/dialog";
import UserForm from "./UserForm.vue";

//import {mapState, mapActions} from "vuex"

export default {
  name: "Chamber Users",
  inheritAttrs: false,
  components: { Dialog, UserForm, ContextMenu },
  created() {
    this.axios.get("/chamber/users").then(response => (this.users = response.data));
    this.axios.get("/system/chambers").then(response => (this.chambers = response.data));
  },
  data() {
    return {
      showUserForm: false,
      selected: {},
      users: [],
      chambers: [],
      loading: false,
      contextMenu: [
        { label: "User Actions", class: "title", disabled: true },
        { separator: true },
        {
          label: "Edit",
          icon: "fas fa-cog",
          command: () => this.edit()
        },
        {
          label: "Activate/Deactivate",
          icon: "fas fa-external-link",
          command: () => this.status()
        },
        {
          label: "Delete",
          icon: "fas fa-external-link",
          command: () => this.confirmDelete()
        }
      ],
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      }
    };
  },

  methods: {
    chamber(cocId, type) {
      let response = this.chambers.find(chamber => chamber.cocId === cocId);
      if (!response) response={
        code: "SD",
        coc: "Swiftdox"
      }
      return response[type];
    }, //chamberName

    onRowContextMenu(event) {
      this.contextMenu[0].label = event.data.firstname + " " + event.data.lastname;
      if (event.data.isActive) {
        this.contextMenu[3].label = "Deactivate";
        this.contextMenu[3].icon = "fas fa-user-lock";
      } else {
        this.contextMenu[3].label = "Activate";
        this.contextMenu[3].icon = "fas fa-user-unlock";
      }
      this.$refs.cm.show(event.originalEvent);
    },

    create() {
      this.selected = {};
      this.edit();
    },
    edit() {
      this.showUserForm = true;
    }, //edit()

    save(data) {
      console.log(data);
      this.axios.post("/chamber/user", data)
      .then(response=>{
      if (data.systemuserId) {
        let userIdx = this.users.indexOf(this.selected);
        this.users[userIdx] = data;
      } else {
        data.systemuserId = response.data;
        this.users.push(data);
      }
      this.showUserForm = false;
      console.log("save complete");

      })
    }, //save()

    confirmDelete() {
      this.$swal({
        //        title: "Delete " + this.selected.name,
        text:
          this.selected.firstname + " " + this.selected.lastname + " will be deleted permanently?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm Delete"
      }).then(response => {
        if (response.isConfirmed) this.delete();
      });
    }, //confirmDelete()

    delete() {
      this.axios.delete("/chamber/user/" + this.selected.systemuserId).then(() => {
        this.users.splice(this.users.indexOf(this.selected), 1);
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          icon: "success",
          title: this.selected.firstname + " " + this.selected.lastname + " is deleted..."
        });
      });
    }, //delet()

    status() {
      this.axios.get(`/chamber/user/${this.selected.systemuserId}/status`).then(response => {
        console.log(response.data);
        let userIdx = this.users.indexOf(this.selected);
        this.users[userIdx].isActive = !this.users[userIdx].isActive;
      });
    } //status()
  },

  computed: {}
};
</script>

<style></style>
