import { required, email, requiredIf } from "@vuelidate/validators";

const hasRole = (_, vm) => {
  return vm.user.isUser || vm.user.isNotary || vm.user.isSignatory;
};

export default function() {
  return {
    user: {
      firstname: { required },
      lastname: { required },
      email: { email, requiredif: requiredIf(this.user.isUser) },
      title: { requiredIf: requiredIf(this.user.isSignatory) },
      isUser: { hasRole },
      isSignatory: { hasRole },
      isNotary: { hasRole },
      gender: { requiredif: requiredIf(this.user.isNotary) }
    }
  };
}
